<template>
    <learning-paths :items="items" class="bleed" />
</template>

<script>
import cmsIcon from '~/mixins/cmsIcon.vue';
import uriMixin from '~/mixins/uri.vue';
import LearningPaths from '~/patterns/molecules/learning-paths/learning-paths.vue';

export default {
    components: {
        LearningPaths
    },

    mixins: [cmsIcon, uriMixin],

    props: {
        learningPaths: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    computed: {
        items() {
            return this.learningPaths.map((learningPath) => {
                return {
                    title: learningPath.title,
                    text: learningPath.text,
                    icon: this.mapCmsIcon(learningPath.icon),
                    uri: this.uriFromEntryFields(learningPath)
                };
            });
        }
    }
};
</script>
