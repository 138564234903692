<template>
    <uro-section class="section--larger-gap section--extra-spacing section--text-image section--wide" :sidebar-position="imageAlignment ? imageAlignment : 'right'">
        <template #title><h2 :id="kebabCase(title)">{{ title }}</h2></template>

        <template #default>
            <div data-allow-anchor v-html="description" /> <!-- eslint-disable-line vue/no-v-html -->
        </template>

        <template v-if="buttonTitle" #footer>
            <uro-button class="uro-button--mobileWide" :element="!isExternal ? 'nuxt-link' : 'a'" v-bind="linkAttrs">
                {{ buttonTitle }}
            </uro-button>
        </template>

        <template v-if="image && image.length" #aside>
            <a
                v-if="allowImageEnlargement"
                :href="image[0].url"
                target="_blank"
                rel="noopener"
                aria-label="Click to enlarge"
                title="Click to enlarge"
            >
                <uro-figure
                    :src="image[0].urlLarge"
                    alt=""
                    :srcset="`${image[0].urlLargest} 929w, ${image[0].urlLarge} 605w, ${image[0].urlMedium} 430w,  ${image[0].urlSmall} 320w`"
                    sizes="(min-width: 1401px) 605px, (min-width: 993px) 430px, 100vw"
                />
            </a>
            <uro-figure
                v-else
                :src="image[0].urlLarge"
                alt=""
                :srcset="`${image[0].urlLargest} 929w, ${image[0].urlLarge} 605w, ${image[0].urlMedium} 430w,  ${image[0].urlSmall} 320w`"
                sizes="(min-width: 1401px) 605px, (min-width: 993px) 430px, 100vw"
            />
        </template>
    </uro-section>
</template>

<script>
import { kebabCase } from 'lodash';

import UroFigure from '~/patterns/molecules/figure/figure.vue';
import UroSection from '~/patterns/molecules/section/section.vue';
import UroButton from '~/patterns/atoms/button/button.vue';
import uriMixin from '~/mixins/uri.vue';

export default {
    components: {
        UroSection,
        UroButton,
        UroFigure
    },

    mixins: [uriMixin],

    props: {
        title: {
            type: String,
            default: null,
            required: true
        },
        description: {
            type: String,
            default: null,
            required: false
        },
        buttonTitle: {
            type: String,
            default: null,
            required: false
        },
        buttonUrl: {
            type: String,
            default: null,
            required: false
        },
        image: {
            type: Array,
            default: null,
            required: true
        },
        imageAlignment: {
            type: String,
            default: 'right',
            required: false
        },
        allowImageEnlargement: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    computed: {
        isExternal() {
            return this.checkIsExternal(this.buttonUrl);
        },
        linkAttrs() {
            if (this.isExternal) {
                return {
                    href: this.buttonUrl,
                    target: 'blank',
                    rel: 'noopener noreferrer'
                };
            } else if (this.buttonUrl) {
                return {
                    to: this.buttonUrl
                };
            }

            return null;
        }
    },

    methods: {
        kebabCase
    },
};
</script>
