<template>
    <uro-section class="quicklinks-section">
        <template #title>
            <slot name="title" />
        </template>

        <quicklinks-list v-for="(list, index) in lists" :key="index" :links="list.links" :title="list.title" />
    </uro-section>
</template>

<script>
import UroSection from '~/patterns/molecules/section/section.vue';
import QuicklinksList from '~/patterns/molecules/quicklinks-list/quicklinks-list.vue';

export default {
    components: {
        UroSection,
        QuicklinksList
    },

    props: {
        lists: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>

<style lang="less">
.quicklinks-section {
    .section__content {
        display: grid;
        grid-template-columns: 1fr;
        gap: var(--spacing-lg);

        @media @q-xs-min {
            grid-template-columns: repeat(auto-fill, minmax(18.5rem, 1fr));
        }

        @media @q-lg-min {
            grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
            gap: var(--spacing-xl);
        }
    }
}
</style>
