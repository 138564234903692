<template>
    <uro-section v-if="platforms">
        <template #title>
            <h2>{{ title }}</h2>
        </template>

        <template v-if="subtitle" #subtitle>
            <p>{{ subtitle }}</p>
        </template>

        <educational-platforms :platforms="platforms" />
    </uro-section>
</template>

<script>
import cmsIcon from '~/mixins/cmsIcon.vue';

import UroSection from '~/patterns/molecules/section/section.vue';
import EducationalPlatforms from '~/patterns/molecules/educational-platforms/educational-platforms.vue';

export default {
    components: {
        UroSection,
        EducationalPlatforms
    },

    mixins: [cmsIcon],

    props: {
        title: {
            type: String,
            required: false,
            default: 'Platforms'
        },
        subtitle: {
            type: String,
            required: false,
            default: null
        },
        externalPlatforms: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    computed: {
        platforms() {
            return this.externalPlatforms.map((platform) => {
                return {
                    title: platform.title,
                    icon: this.mapCmsIcon(platform.icon),
                    url: platform.url
                };
            });
        }
    }
};
</script>
