import { render, staticRenderFns } from "./quote.vue?vue&type=template&id=434d2372&functional=true"
var script = {}
import style0 from "./quote.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports