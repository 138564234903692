<template>
    <main class="layout__main layout__main--no-top-padding">
        <transition name="fade">
            <uro-error v-if="$fetchState.error || notFound" :error="$fetchState.error || { statusCode: 404 }" />

            <uro-hero
                v-if="!$fetchState.error && !notFound"
                :image="image"
                :placeholder="$fetchState.pending"
            >
                <template #text>
                    <h2 v-if="home && home.headerText" data-allow-anchor v-html="home.headerText" /> <!-- eslint-disable-line vue/no-v-html -->
                </template>

                <template #banner>
                    <uro-upcoming v-if="home && home.highlight.length" :uri="home.highlight[0].entry.length ? home.highlight[0].entry[0].uri : null">
                        <template #title>{{ home.highlight[0].title }}</template>
                        <template #default>{{ home.highlight[0].text }}</template>
                        <template v-if="home.highlight[0].entry.length" #meta>{{ formatHighlightDate(home.highlight[0].entry[0]) }}</template>
                    </uro-upcoming>
                </template>
            </uro-hero>
        </transition>

        <article v-if="!$fetchState.error && !notFound" class="bleed">
            <uro-topics v-if="home && home.selectedTopics.length" :topics="home.selectedTopics[0].topics">
                <template #title>
                    {{ home.selectedTopics[0].title }}
                </template>
                <template #description>
                    {{ home.selectedTopics[0].description }}
                </template>
            </uro-topics>

            <flexible-sections
                :sections="home ? home.sections : []"
                :placeholder="$fetchState.pending ? 'overview' : null"
                data-wide
            />
        </article>
    </main>
</template>

<script>
import homeQuery from '~/graphql/queries/pages/home.graphql';

import UroError from '~/patterns/molecules/error.vue';
import UroUpcoming from '~/patterns/molecules/upcoming/upcoming.vue';
import UroTopics from '~/patterns/molecules/topics/topics.vue';

import UroHero from '~/patterns/organisms/hero/hero.vue';
import FlexibleSections from '~/patterns/organisms/flexible-sections/flexible-sections.vue';

import seoMeta from '~/mixins/seoMeta.vue';
import { formatDateRange } from '~/helpers/date';

export default {
    components: {
        UroTopics,
        UroHero,
        FlexibleSections,
        UroError,
        UroUpcoming,
    },

    mixins: [seoMeta],

    scrollToTop: true,

    data() {
        return {
            home: null,
            news: [],
            events: [],
            notFound: null,
        };
    },

    async fetch() {
        const { data } = await this.$gql.executeQuery(homeQuery);

        if (!data || !data.home) {
            this.notFound = true;
            // set status code on server
            if (process.server) {
                this.$nuxt.context.res.statusCode = 404;
            }
        }
        this.home = data?.home;
    },

    head() {
        return this.mapSeoMetaFromCraft(this.home?.seo, this.home);
    },

    computed: {
        image() {
            return {
                small: this.home?.headerImage?.[0]?.urlSmall,
                medium: this.home?.headerImage?.[0]?.urlMedium,
                large: this.home?.headerImage?.[0]?.urlLarge,
                largest: this.home?.headerImage?.[0]?.urlLargest
            };
        }
    },

    methods: {
        formatHighlightDate(highlight) {
            if (!highlight) {
                return;
            }

            // Prefer dateRange
            if (highlight.dateRange) {
                return formatDateRange(highlight.dateRange.start, highlight.dateRange.end);
            }

            // Fall back to postdate
            return formatDateRange(highlight.postDate, highlight.postDate);
        },
    }
};
</script>
