<template>
    <div class="learning-paths">
        <drag-to-scroll
            ref="scrollContainer"
            class="learning-paths__wrapper"
        >
            <div class="learning-paths__padding" />

            <learning-path-card
                v-for="(item, key) in items"
                ref="cards"
                :key="key"
                :item="item"
                class="drag-to-scroll__ignore"
                :uri="item.uri"
                draggable="false"
            >
                <template #header>
                    <uro-icon :icon="item.icon" :auto-color="false" />
                </template>
                <template #title>{{ item.title }}</template>
                <template #default>{{ item.text }}</template>
            </learning-path-card>

            <div class="learning-paths__padding" />
        </drag-to-scroll>

        <button
            v-if="overflowLeft"
            data-left
            class="learning-paths__control"
            type="button"
            aria-label="Scroll left"
            @click.prevent="scroll('left')"
        >
            <uro-icon icon="arrow-left" />
        </button>

        <button
            v-if="overflowRight"
            data-right
            class="learning-paths__control"
            type="button"
            aria-label="Scroll right"
            @click.prevent="scroll('right')"
        >
            <uro-icon icon="arrow-right" />
        </button>
    </div>
</template>

<script>
import DragToScroll from '~/patterns/atoms/drag-to-scroll/drag-to-scroll.vue';
import LearningPathCard from '~/patterns/atoms/learning-path-card/learning-path-card.vue';

export default {
    components: {
        DragToScroll,
        LearningPathCard
    },

    props: {
        items: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    },

    data() {
        return {
            overflowLeft: false,
            overflowRight: false
        };
    },

    mounted() {
        this.calculatePositions();

        window.addEventListener('resize', this.calculatePositions);
        this.$refs.scrollContainer.$el.addEventListener('scroll', this.calculatePositions);
    },

    destroyed() {
        window.removeEventListener('resize', this.calculatePositions);
    },

    methods: {
        calculatePositions(futureScrollPosition) {
            const firstCardOffset = this.$refs.cards?.[0]?.$el.getBoundingClientRect(),
                lastCardOffset = this.$refs.cards?.[this.$refs.cards.length - 1]?.$el.getBoundingClientRect();
            // these are relative to the viewport, i.e. the window
            this.overflowLeft = firstCardOffset.left < 0;
            this.overflowRight = lastCardOffset.right > window.innerWidth;
        },

        scroll(direction) {
            let cardWidth = 0;
            if (this.$refs.cards.length) {
                cardWidth = this.$refs.cards[0].$el.offsetWidth;
            }

            const element = this.$refs.scrollContainer.$el;
            let scrollX = 0;
            if (direction === 'left') {
                scrollX = element.scrollLeft - cardWidth;
            } else if (direction === 'right') {
                scrollX = element.scrollLeft + cardWidth;
            } else {
                return;
            }

            element.scrollTo({
                left: scrollX,
                behavior: 'smooth'
            });

            this.calculatePositions(scrollX);
        }
    }
};
</script>

<style lang="less" src="./learning-paths.less"></style>
