<template>
    <uro-section>
        <uro-box>
            <template #title>
                <template v-if="title">{{ title }}</template>
            </template>

            <template #default>
                <div data-allow-anchor v-html="richText" /> <!-- eslint-disable-line vue/no-v-html -->
            </template>
        </uro-box>
    </uro-section>
</template>

<script>
import UroSection from '~/patterns/molecules/section/section.vue';
import uriMixin from '~/mixins/uri.vue';
import UroBox from '~/patterns/molecules/box/box.vue';

export default {
    components: {
        UroSection,
        UroBox
    },

    mixins: [uriMixin],

    props: {
        title: {
            type: String,
            required: false,
            default: 'Warning'
        },
        richText: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>
