<template>
    <uro-section v-if="books.length || $fetchState.pending">
        <template #title>
            <h2>Programmes</h2>
        </template>

        <guideline-cards :cards="booksCards" :is-loading="$fetchState.pending" />
    </uro-section>
</template>

<script>
import booksByTopicsQuery from '~/graphql/queries/books/byTopic.graphql';

import UroSection from '~/patterns/molecules/section/section.vue';
import GuidelineCards from '~/patterns/molecules/guideline-cards/guideline-cards.vue';

export default {
    components: {
        UroSection,
        GuidelineCards
    },

    data() {
        return {
            books: []
        };
    },

    async fetch() {
        const { data } = await this.$gql.executeQuery(booksByTopicsQuery, { slug: this.$route.params.slug });
        this.books = data?.books;
    },

    computed: {
        booksCards() {
            return this.books.map((book) => {
                return {
                    title: book.title,
                    url: '/' + book.uri
                };
            });
        }
    }
};
</script>
