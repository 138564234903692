<template>
    <uro-section>
        <template #title>
            <component :is="headerTextSize" v-if="headerText">{{ headerText }}</component>
        </template>

        <template #default>
            <div data-allow-anchor v-html="richText" /> <!-- eslint-disable-line vue/no-v-html -->
        </template>
    </uro-section>
</template>

<script>
import UroSection from '~/patterns/molecules/section/section.vue';

export default {
    components: {
        UroSection
    },

    props: {
        headerText: {
            type: String,
            default: null,
            required: false
        },
        headerTextSize: {
            type: String,
            default: null,
            required: false
        },
        richText: {
            type: String,
            default: null,
            required: true
        }
    }
};
</script>
