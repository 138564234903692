<template>
    <transition name="fade">
        <div v-if="sections.length" class="flexible-sections">
            <component
                :is="getSectionComponent(section)"
                v-for="(section, key) in sections"
                :key="key"
                :lists="lists"
                v-bind="getProps(section)"
                class="flexible-sections__section"
            />
        </div>
        <content-placeholders v-else-if="placeholder" class="flexible-sections">
            <template v-if="placeholder === 'content'">
                <uro-section>
                    <template #default>
                        <content-placeholders-img />
                    </template>
                </uro-section>

                <uro-section>
                    <template #title>
                        <content-placeholders-heading />
                    </template>

                    <template #default>
                        <content-placeholders-text :lines="10" />
                    </template>
                </uro-section>

                <uro-section v-for="i in [1,2,3]" :key="i">
                    <template #title>
                        <content-placeholders-heading />
                    </template>

                    <template #default>
                        <content-placeholders-text />
                    </template>
                </uro-section>
            </template>

            <template v-if="placeholder === 'overview'">
                <uro-section>
                    <template #default>
                        <content-placeholders-img />
                    </template>
                </uro-section>

                <uro-section>
                    <template #title>
                        <content-placeholders-heading />
                    </template>

                    <template #default>
                        <content-placeholders-text :lines="4" />
                    </template>
                </uro-section>

                <uro-section>
                    <template #default>
                        <content-placeholders-img />
                    </template>
                </uro-section>

                <uro-section v-for="i in [1,2,3]" :key="i">
                    <template #title>
                        <content-placeholders-heading />
                    </template>

                    <template #default>
                        <content-placeholders-text :lines="2" />
                    </template>
                </uro-section>

                <uro-section>
                    <template #default>
                        <content-placeholders-img />
                    </template>
                </uro-section>
            </template>
        </content-placeholders>
    </transition>
</template>

<script>
import UroSections from './sections/sections.vue';
import UroEvents from './sections/events.vue';
import UroGuidelines from './sections/guidelines.vue';
import UroBooks from './sections/books.vue';
import UroPlatforms from './sections/platforms.vue';
import UroTextImage from './sections/text-image.vue';
import UroRichText from './sections/text.vue';
import UroButton from './sections/button.vue';
import UroCards from './sections/cards.vue';
import UroQuote from './sections/quote.vue';
import UroEmbed from './sections/embed.vue';
import UroImage from './sections/image.vue';
import UroNews from './sections/news.vue';
import UroLinksLists from './sections/links-lists.vue';
import UroLearningPaths from './sections/learning-paths.vue';
import UroWarning from './sections/warning.vue';
import UroAccordion from './sections/accordion.vue';
import UroEndorsements from './sections/endorsements.vue';
import UroBoardOverview from './sections/board-overview.vue';
import UroBoard from './sections/board.vue';
import UroSection from '~/patterns/molecules/section/section.vue';

export default {
    components: {
        UroSection,
        UroSections,
        UroEvents,
        UroGuidelines,
        UroBooks,
        UroPlatforms,
        UroTextImage,
        UroRichText,
        UroButton,
        UroCards,
        UroQuote,
        UroEmbed,
        UroImage,
        UroNews,
        UroLinksLists,
        UroLearningPaths,
        UroWarning,
        UroAccordion,
        UroEndorsements,
        UroBoardOverview,
        UroBoard
    },

    props: {
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        sections: {
            type: Array,
            required: true,
            default: () => { return []; }
        },
        members: {
            type: Array,
            required: false,
            default: () => { return []; }
        },
        lists: {
            type: Array,
            default: () => [],
            required: true
        }
    },

    data() {
        return {
            map: {
                flexibleSection_richText_BlockType: UroRichText,
                flexibleSection_button_BlockType: UroButton,
                flexibleSection_cards_BlockType: UroCards,
                flexibleSection_quote_BlockType: UroQuote,
                flexibleSection_embed_BlockType: UroEmbed,
                flexibleSection_image_BlockType: UroImage,
                flexibleSection_warning_BlockType: UroWarning,
                flexibleSection_accordion_BlockType: UroAccordion,
                flexibleSection_endorsements_BlockType: UroEndorsements,
                flexibleSection_boardOverview_BlockType: UroBoardOverview,
                flexibleSection_board_BlockType: UroBoard,
                flexibleSection_imageCTA_BlockType: UroTextImage,
                overviewFlexibleSections_educationalPlatforms_BlockType: UroPlatforms,
                overviewFlexibleSections_sections_BlockType: UroSections,
                overviewFlexibleSections_news_BlockType: UroNews,
                overviewFlexibleSections_events_BlockType: UroEvents,
                overviewFlexibleSections_imageCTA_BlockType: UroTextImage,
                overviewFlexibleSections_linkLists_BlockType: UroLinksLists,
                overviewFlexibleSections_learningPaths_BlockType: UroLearningPaths,
                overviewFlexibleSections_guidelines_BlockType: UroGuidelines,
                overviewFlexibleSections_books_BlockType: UroBooks,
                guidelinesRelatedContent_news_BlockType: UroNews,
                guidelinesRelatedContent_imageCTA_BlockType: UroTextImage,
                guidelinesRelatedContent_events_BlockType: UroEvents
            }
        };
    },

    methods: {
        getProps(section) {
            const props = { ...section };
            if (section.__typename === 'flexibleSection_boardOverview_BlockType') {
                return {
                    ...props,
                    boardMembers: this.members
                };
            }
            return props;
        },

        getSectionComponent(section) {
            if (section.component) {
                return section.component;
            }
            if (section.__typename) {
                return this.map[section.__typename];
            }
            return 'div';
        }
    }
};
</script>

<style lang="less">
.flexible-sections {
    .grid-container(true, var(--grid-maxWidth-content));
}

.flexible-sections[data-wide] {
    .grid-container(true, var(--grid-maxWidth-page));

    .section__title h2 {
        .typography-h3;
    }
}
</style>
