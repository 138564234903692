<template>
    <uro-section>
        <template #title>
            <h2 v-show="title">{{ title }}</h2>
        </template>
        <template #default>
            <uro-accordion :items="accordionItems">
                <template #title="{ item }">
                    {{ item.title }}
                </template>
                <template #default="{ item }">
                    <div data-allow-anchor v-html="item.content" /> <!-- eslint-disable-line vue/no-v-html -->
                </template>
            </uro-accordion>
        </template>
    </uro-section>
</template>

<script>
import UroSection from '~/patterns/molecules/section/section.vue';
import uriMixin from '~/mixins/uri.vue';
import UroAccordion from '~/patterns/molecules/accordion/accordion.vue';

export default {
    components: {
        UroSection,
        UroAccordion
    },

    mixins: [uriMixin],

    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        items: {
            type: Array,
            required: false,
            default: null
        }
    },

    computed: {
        accordionItems() {
            return this.items.map((item) => {
                return {
                    title: item.blockTitle,
                    content: item.richText
                };
            });
        }
    }
};
</script>
