<template>
    <section class="topics">
        <div class="topics__wrapper">
            <h2 v-if="$slots.title" class="topics__title"><slot name="title" /></h2>
            <p v-if="$slots.description" class="topics__description"><slot name="description" /></p>

            <div v-if="topics.length" class="topics__pills">
                <uro-pill v-for="topic in topics" :key="topic.uri" :class="pillsClass" element="nuxt-link" :to="`/${topic.uri}`">{{ topic.title }}</uro-pill>
            </div>
        </div>
    </section>
</template>

<script>
import UroPill from '~/patterns/atoms/pill/pill.vue';

export default {
    components: {
        UroPill
    },

    props: {
        topics: {
            type: Array,
            required: false,
            default: () => []
        },
        pillsClass: {
            type: String,
            required: false,
            default: 'pill--square'
        }
    }
};
</script>

<style lang="less" src="./topics.less" />
