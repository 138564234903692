<template>
    <div class="educational-platforms">
        <uro-platform
            v-for="(platform, index) in platforms"
            :key="index"
            :url="platform.url"
            class="educational-platforms__platform"
        >
            <template #title>
                {{ platform.title }}
            </template>

            <template #icon>
                <uro-icon :icon="platform.icon" :auto-color="false" />
            </template>
        </uro-platform>
    </div>
</template>

<script>
import UroPlatform from '~/patterns/atoms/platform/platform.vue';

export default {
    components: {
        UroPlatform
    },
    props: {
        platforms: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>

<style lang="less" src="./educational-platforms.less" />
