<template>
    <uro-section>
        <template #title>
            <h2 v-if="title">{{ title }}</h2>
            <h2 v-else>Sections</h2>
        </template>

        <template v-if="subtitle" #subtitle>
            {{ subtitle }}
        </template>

        <uro-banners v-if="sections && sections.length" :items="sections" />
    </uro-section>
</template>

<script>
import UroBanners from '~/patterns/molecules/banners/banners.vue';
import UroSection from '~/patterns/molecules/section/section.vue';

export default {
    components: {
        UroBanners,
        UroSection
    },

    props: {
        title: {
            type: String,
            default: 'Sections',
            required: false
        },
        subtitle: {
            type: String,
            default: null,
            required: false
        },
        sections: {
            type: Array,
            default: null,
            required: false
        }
    }
};
</script>
