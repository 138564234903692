<template>
    <uro-section v-if="guidelines.length || $fetchState.pending">
        <template #title>
            <h2>Guidelines</h2>
        </template>

        <guideline-cards :cards="guidelineCards" :is-loading="$fetchState.pending" />
    </uro-section>
</template>

<script>
import guidelinesByTopicsQuery from '~/graphql/queries/guidelines/byTopic.graphql';

import UroSection from '~/patterns/molecules/section/section.vue';
import GuidelineCards from '~/patterns/molecules/guideline-cards/guideline-cards.vue';

export default {
    components: {
        UroSection,
        GuidelineCards
    },

    data() {
        return {
            guidelines: []
        };
    },

    async fetch() {
        const { data } = await this.$gql.executeQuery(guidelinesByTopicsQuery, { slug: this.$route.params.slug });
        this.guidelines = data?.guidelines;
    },

    computed: {
        guidelineCards() {
            return this.guidelines.map((guideline) => {
                return {
                    title: guideline.title,
                    url: '/' + guideline.uri
                };
            });
        }
    }
};
</script>
