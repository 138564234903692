<template>
    <uro-section v-if="image && image.length">
        <uro-figure
            :src="image[0].intermediateUrl"
            :srcset="`${image[0].mobileUrl} 320w, ${image[0].intermediateUrl} 600w, ${image[0].desktopUrl} 844w`"
            width="844"
            sizes="100vw"
            alt=""
        >
            <template #caption>{{ caption }}</template>
        </uro-figure>
    </uro-section>
</template>

<script>
import UroSection from '~/patterns/molecules/section/section.vue';
import UroFigure from '~/patterns/molecules/figure/figure.vue';

export default {
    components: {
        UroFigure,
        UroSection
    },

    props: {
        caption: {
            type: String,
            default: null,
            required: false
        },
        image: {
            type: Array,
            default: null,
            required: true
        }
    }
};
</script>
