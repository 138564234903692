<template>
    <a
        :href="url"
        target="blank"
        rel="noopener"
        class="uro-endorsement"
    >
        <div>
            <uro-figure
                v-if="image && image.length"
                :src="image[0].intermediateUrl"
                :srcset="`${image[0].mobileUrl} 320w, ${image[0].intermediateUrl} 600w, ${image[0].desktopUrl} 844w`"
                width="844"
                sizes="100vw"
                alt=""
            />
            <span>{{ title }}</span>
        </div>
    </a>
</template>

<script>
import UroFigure from '~/patterns/molecules/figure/figure.vue';

export default {
    components: {
        UroFigure,
    },

    props: {
        title: {
            type: String,
            default: null,
            required: false
        },
        image: {
            type: Array,
            default: null,
            required: true
        },
        url: {
            type: [Object, String],
            default: null,
            required: false
        }
    }
};
</script>

<style lang="less" src="./endorsement.less" />
