<template>
    <uro-section v-if="blockEmbedUrl">
        <uro-figure alt="">
            <uro-embed :url="blockEmbedUrl" :height="blockEmbedHeight" :description="blockEmbedDescription" />
            <figcaption v-if="blockEmbedDescription">
                {{ blockEmbedDescription }}
            </figcaption>
        </uro-figure>
    </uro-section>
</template>

<script>
import UroFigure from '~/patterns/molecules/figure/figure.vue';
import UroSection from '~/patterns/molecules/section/section.vue';
import UroEmbed from '~/patterns/molecules/embeds/embed.vue';

export default {
    components: {
        UroFigure,
        UroSection,
        UroEmbed
    },

    props: {
        blockEmbedUrl: {
            type: [String, null],
            default: null,
            required: false
        },
        blockEmbedDescription: {
            type: String,
            default: null,
            required: false
        },
        blockEmbedHeight: {
            type: String,
            default: undefined,
            required: false
        }
    }
};
</script>
