<template>
    <component :is="implementationType" v-if="implementationType " :url="url" :height="height" :description="description" />
</template>

<script>
export default {
    components: {
        'embed-buzzsprout': () => import('./implementations/buzzsprout.vue'),
        'embed-issuu': () => import('./implementations/issuu.vue'),
        'embed-flippingbook': () => import('./implementations/flippingbook.vue'),
        'embed-vimeo': () => import('./implementations/vimeo.vue'),
        'embed-youtube': () => import('./implementations/youtube.vue'),
        'embed-iframe': () => import('./implementations/iframe.vue')
    },

    props: {
        url: {
            type: String,
            required: true,
            default: ''
        },
        height: {
            type: String,
            required: false,
            default: undefined
        },
        description: {
            type: String,
            required: false,
            default: undefined
        }
    },

    computed: {
        implementationType() {
            if (!this.url) {
                return null;
            }
            if (this.url?.includes('vimeo.com/')) {
                return 'embed-vimeo';
            }
            if (this.url?.includes('issuu.com/')) {
                return 'embed-issuu';
            }
            if (this.url?.includes('online.flippingbook.com/') || this.url?.includes('publications.uroweb.org/')) {
                return 'embed-flippingbook';
            }
            if (this.url?.includes('buzzsprout.com/')) {
                return 'embed-buzzsprout';
            }
            if (this.url?.includes('https://youtu.be/')) {
                return 'embed-youtube';
            }
            if (this.url?.includes('v=')) {
                return 'embed-youtube';
            }
            return 'embed-iframe';
        }
    }
};
</script>

<style lang="less" src="./embed.less" />
