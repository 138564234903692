<template>
    <quicklinks-section v-if="linkLists.length" :lists="linkLists" />
</template>

<script>
import uriVue from '~/mixins/uri.vue';
import QuicklinksSection from '~/patterns/organisms/quicklinks-section/quicklinks-section.vue';

export default {

    components: {
        QuicklinksSection
    },
    mixins: [
        uriVue
    ],

    props: {
        lists: {
            type: Array,
            default: () => [],
            required: true
        }
    },

    computed: {
        linkLists() {
            return this.lists.map((list) => {
                return {
                    title: list.title,
                    links: list.links.map((link) => {
                        return {
                            text: link.text,
                            uri: this.uriFromEntryFields(link)
                        };
                    })
                };
            });
        }
    },
};
</script>
