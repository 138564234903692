<template>
    <header class="hero">
        <transition name="fade">
            <template v-if="image">
                <img :src="image.large" :srcset="`${image.small} 480w, ${image.medium} 768w, ${image.large} 992w, ${image.largest} 1440w `" sizes="100vw" alt="" class="hero__background">
            </template>
            <template v-else-if="placeholder">
                <content-placeholders-img class="hero__background" />
            </template>
        </transition>

        <div v-if="$slots.text || placeholder" class="hero__text">
            <slot name="text"><content-placeholders-text :lines="2" /></slot>
        </div>

        <div v-if="$slots.banner" class="hero__banner">
            <slot name="banner" />
        </div>
    </header>
</template>

<script>
export default {
    props: {
        placeholder: {
            type: Boolean,
            required: false,
            default: false
        },
        image: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less" src="./hero.less" />
