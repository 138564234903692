<template>
    <uro-section>
        <template #title>
            <h2 v-show="title">{{ title }}</h2>
        </template>
        <uro-endorsements :items="items" />
    </uro-section>
</template>

<script>
import UroSection from '~/patterns/molecules/section/section.vue';
import UroEndorsements from '~/patterns/organisms/endorsements/endorsements.vue';

export default {
    components: {
        UroSection,
        UroEndorsements
    },

    props: {
        title: {
            type: String,
            required: false,
            default: null
        },
        items: {
            type: Array,
            required: false,
            default: null
        }
    }
};
</script>
