<template>
    <a :href="url" target="blank" rel="noopener noreferrer" class="platform">
        <div class="platform__icon">
            <slot name="icon" />
        </div>

        <h3 class="platform__title">
            <slot name="title" />
        </h3>

        <p class="platform__instruction">
            <slot name="instruction">Go to platform <uro-icon icon="arrow-up-right" /></slot>
        </p>
    </a>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true,
            default: ''
        }
    }
};
</script>

<style lang="less" src="./platform.less" />
