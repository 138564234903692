<template>
    <uro-section class="board section--wide section--extra-spacing">
        <template v-if="title" #title>
            <h2>{{ title }}</h2>
        </template>

        <section v-if="memberObject" class="board__section">
            <uro-member v-for="(memberItem, index) in member" :key="`member${index}`">
                <template v-if="memberItem.memberPicture && memberItem.memberPicture.length" #picture>
                    <img :src="memberItem.memberPicture[0].url" alt="" loading="lazy" width="80">
                </template>

                <template #default>
                    <header>
                        <h5>{{ memberItem.memberTitle }} {{ memberItem.memberInitials }} {{ memberItem.memberLastName }} <template v-if="memberItem.memberCountryCode">({{ memberItem.memberCountryCode }})</template></h5>
                        <div v-for="(type, typeIndex) in cleanMemberTypes" :key="`member-type-${typeIndex}`">{{ memberItem.memberType }}</div>
                    </header>
                </template>
            </uro-member>
        </section>
        <section>
            <uro-banners
                v-if="offices && offices.length"
                :items="offices"
                :secondary-style="true"
                class="banner--extra-spacing"
            />
        </section>
    </uro-section>
</template>

<script>
import UroSection from '~/patterns/molecules/section/section.vue';
import UroMember from '~/patterns/atoms/member/member.vue';
import UroBanners from '~/patterns/molecules/banners/banners.vue';

import uriMixin from '~/mixins/uri.vue';

export default {
    components: {
        UroSection,
        UroMember,
        UroBanners
    },

    mixins: [uriMixin],

    props: {
        title: {
            type: String,
            required: false,
            default: 'Board'
        },
        member: {
            type: Array,
            required: false,
            default: null
        },
        offices: {
            type: Array,
            required: false,
            default: null
        }
    },

    computed: {
        memberObject() {
            return this.member && this.member.length ? this.member[0] : null;
        },
        cleanMemberTypes() {
            return this.memberObject && this.memberObject.memberType ? this.memberObject.memberType.split(' & ') : [];
        }
    }
};
</script>
<style lang="less">
.board__section {
    display: grid;
    gap: var(--spacing-lg);
    margin-top: var(--spacing-lg);

    @media @q-md-min {
        grid-template-columns: repeat(2, 1fr);
    }

    @media @q-lg-min {
        grid-template-columns: repeat(3, 1fr);
        margin-top: var(--spacing-xl);
        gap: var(--spacing-xl);
    }
}

.board[data-width='2']  .board__section {
    @media @q-lg-min {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
