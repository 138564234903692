<template>
    <component :is="element" v-bind="attributes" class="learning-path-card">
        <header class="learning-path-card__header">
            <slot name="header" />
        </header>

        <h4 class="learning-path-card__title">
            <slot name="title" />
        </h4>

        <div class="learning-path-card__text">
            <slot />
        </div>
    </component>
</template>

<script>
import uriMixin from '~/mixins/uri.vue';

export default {
    mixins: [uriMixin],

    props: {
        uri: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        element() {
            if (!this.uri) {
                return 'article';
            }
            if (this.checkIsExternal(this.uri)) {
                return 'a';
            }
            return 'nuxt-link';
        },

        attributes() {
            if (!this.uri) {
                return null;
            }
            if (this.checkIsExternal(this.uri)) {
                return {
                    href: this.uri,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                };
            }
            return {
                to: this.uri
            };
        }
    }
};
</script>

<style lang="less" src="./learning-path-card.less" />
