<template>
    <uro-section class="section--wide">
        <template #title>
            <h2>Board</h2>
        </template>
        <uro-members :members="boardMembers"/>
    </uro-section>
</template>

<script>
import UroMembers from '~/patterns/molecules/members/members.vue';
import UroSection from '~/patterns/molecules/section/section.vue';

export default {
    components: {
        UroMembers,
        UroSection
    },

    props: {
        boardMembers: {
            type: Array,
            required: true
        }
    }
};
</script>
