<template>
    <div
        v-if="enableDragToScroll"
        v-dragscroll
        class="drag-to-scroll"
        :class="(dragging) ? 'is-dragging' : ''"
        @dragscrollstart="handleDragStart()"
        @dragscrollend="handleDragEnd()"
    >
        <slot />
    </div>
    <div v-else>
        <slot />
    </div>
</template>

<script>
'use strict';

export default {
    data() {
        return {
            dragging: false,
            supportsTouch: false
        };
    },

    computed: {
        enableDragToScroll() {
            return !this.supportsTouch;
        }
    },

    mounted() {
        if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
            this.supportsTouch = true;
        }
    },

    methods: {
        handleDragStart() {
            this.dragging = true;
        },
        handleDragEnd() {
            this.dragging = false;
        }
    }
};
</script>

<style lang="less" src="./drag-to-scroll.less"></style>
