<template>
    <component :is="uri ? 'nuxt-link' : 'article'" :to="uri" class="upcoming">
        <h4 v-if="$slots.title" class="upcoming__title"><slot name="title" /></h4>
        <p class="upcoming__text"><slot /></p>
        <p v-if="$slots.meta" class="upcoming__meta"><slot name="meta" /></p>
    </component>
</template>

<script>
export default {
    props: {
        uri: {
            type: String,
            default: '',
            required: false
        }
    }
};
</script>

<style lang="less" src="./upcoming.less" />
