<template>
    <uro-section>
        <template #title><h2 :id="kebabCase(title)">{{ title }}</h2></template>

        <template v-if="subtitle" #subtitle>
            <p>{{ subtitle }}</p>
        </template>

        <template #action>
            <template v-if="eventType === 'live'">
                <nuxt-link v-if="$route.name === 'topics-slug' && ($route.params && $route.params.slug)" :to="'/education-events/events?topic=' + $route.params.slug" aria-label="See all events">See all <uro-icon icon="chevron-right" /></nuxt-link>
                <nuxt-link v-else to="/education-events/events" aria-label="See all events">See all <uro-icon icon="chevron-right" /></nuxt-link>
            </template>
            <template v-else>
                <nuxt-link v-if="$route.name === 'topics-slug' && ($route.params && $route.params.slug)" :to="'/education-events/education?topic=' + $route.params.slug" aria-label="See all on-demand education">See all <uro-icon icon="chevron-right" /></nuxt-link>
                <nuxt-link v-else to="/education-events/education" aria-label="See all on-demand education">See all <uro-icon icon="chevron-right" /></nuxt-link>
            </template>
        </template>

        <card-collection v-if="cards && cards.length" :cards="cards" />
        <p v-else>Sorry, there are no items to show here right now.</p>
    </uro-section>
</template>

<script>
import { kebabCase } from 'lodash';
import { startOfDay } from 'date-fns';

import relatedLiveEventsQuery from '~/graphql/queries/events/relatedLive.graphql';
import relatedOnDemandEventsQuery from '~/graphql/queries/events/relatedOnDemand.graphql';

import eventCardsMixin from '~/mixins/eventCards.vue';
import pillsCards from '~/mixins/pillsCards.vue';

import UroSection from '~/patterns/molecules/section/section.vue';
import CardCollection from '~/patterns/molecules/card-collection/card-collection.vue';

export default {
    components: {
        UroSection,
        CardCollection
    },

    mixins: [
        eventCardsMixin,
        pillsCards
    ],

    props: {
        eventType: {
            type: String,
            required: false,
            default: 'live'
        },
        title: {
            type: String,
            default: null,
            required: true
        },
        subtitle: {
            type: String,
            default: null,
            required: false
        },
        events: {
            type: Array,
            default: () => [],
            required: true
        },
    },

    data() {
        return {
            futureEvents: []
        };
    },

    async fetch() {
        // Make sure any manually selected events are in the future or ongoing
        this.filterFutureEvents();

        // If less than 3 or 4 events were passed, get the remaning amount here
        if (!this.events || this.events.length < this.cardLimit) {
            // Determine how many remaining items to load
            const limit = this.futureEvents.length ? this.cardLimit - this.futureEvents.length : this.cardLimit;
            const excludeIds = this.futureEvents ? this.futureEvents.map(({ id }) => id) : [];

            const variables = {
                limit,
                exclude: ['not', ...excludeIds]
            };

            // On specific pages, show only related items.
            if (this.$route.name === 'topics-slug') {
                variables.relatedToEntries = [{ slug: this.$route.params.slug, type: 'topic' }];
            }

            let result;
            if (this.eventType === 'live') {
                result = await this.$gql.executeQuery(relatedLiveEventsQuery, variables);
            } else {
                result = await this.$gql.executeQuery(relatedOnDemandEventsQuery, variables);
            }

            const data = result?.data;

            if (!data) {
                return;
            }

            if (data.ongoing && data.ongoing.length) {
                this.futureEvents.push.apply(this.futureEvents, data.ongoing);
            }

            // Append any future events as needed
            if (this.futureEvents.length < this.cardLimit && data.future.length) {
                const remaining = this.cardLimit - this.futureEvents.length;
                this.futureEvents.push.apply(this.futureEvents, data.future.slice(0, remaining));
            }
        } else {
            // Max events selected, so we fill future events with selected events.
            this.futureEvents = this.events;
        }
    },

    computed: {
        cardLimit() {
            if (this.$route.name === 'related-content') {
                return 4;
            } else {
                return 3;
            }
        },

        cards() {
            return this.futureEvents.map((event) => {
                let image = null;
                if (event.image.length) {
                    image = {
                        src: event.image?.[0]?.large,
                        srcset: `${event.image?.[0]?.large} 600w, ${event.image?.[0]?.medium} 407w, ${event.image?.[0]?.small} 327w`
                    };
                }
                return {
                    image,
                    title: event.title,
                    meta: this.makeMeta(event),
                    location: this.makeLocation(event),
                    uri: event.uri,
                    pills: this.getCardPills(event)
                };
            });
        }
    },

    methods: {
        kebabCase,
        filterFutureEvents() {
            this.futureEvents = this.events.filter((event) => {
                return this.isFuture(event) || this.isOngoing(event);
            });
        },

        isFuture(event) {
            return event.dateRange?.start >= startOfDay(new Date()).toISOString();
        },

        isOngoing(event) {
            return event.dateRange?.start < startOfDay(new Date()).toISOString() &&
                event.dateRange?.end >= startOfDay(new Date()).toISOString();
        }
    }
};
</script>
