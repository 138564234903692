<template>
    <uro-section v-if="linkAttrs">
        <uro-button :element="linkAttrs.to ? 'nuxt-link' : 'a'" v-bind="linkAttrs">
            {{ blockButtonTitle }}
        </uro-button>
    </uro-section>
</template>

<script>
import UroButton from '~/patterns/atoms/button/button.vue';
import UroSection from '~/patterns/molecules/section/section.vue';
import uriMixin from '~/mixins/uri.vue';

export default {
    components: {
        UroButton,
        UroSection
    },

    mixins: [uriMixin],

    props: {
        blockButtonUrl: {
            type: String,
            required: false,
            default: null
        },
        blockButtonInternalUrl: {
            type: Array,
            required: false,
            default: null
        },
        blockButtonFile: {
            type: Array,
            required: false,
            default: null
        },
        blockButtonMailTo: {
            type: String,
            required: false,
            default: null
        },
        blockButtonTitle: {
            type: String,
            required: true,
            default: ''
        }
    },

    computed: {
        isExternal() {
            return this.checkIsExternal(this.blockButtonUrl);
        },

        linkAttrs() {
            if (this.blockButtonInternalUrl && this.blockButtonInternalUrl.length) {
                return {
                    to: `/${this.blockButtonInternalUrl[0]?.uri}`
                };
            }

            if (this.blockButtonFile && this.blockButtonFile.length) {
                return {
                    href: this.blockButtonFile[0]?.url,
                    target: 'blank',
                    rel: 'noopener noreferrer'
                };
            }

            if (this.blockButtonMailTo) {
                return {
                    href: 'mailto:' + this.blockButtonMailTo,
                    rel: 'noopener noreferrer'
                };
            }

            if (this.isExternal) {
                return {
                    href: this.blockButtonUrl,
                    target: 'blank',
                    rel: 'noopener noreferrer'
                };
            } else if (this.blockButtonUrl) {
                return {
                    to: this.blockButtonUrl
                };
            }

            return null;
        }
    }
};
</script>
