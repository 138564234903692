<template>
    <uro-section>
        <template #title><h2 :id="kebabCase(title)">{{ title }}</h2></template>

        <template v-if="subtitle" #subtitle>
            <p>{{ subtitle }}</p>
        </template>

        <template #action>
            <nuxt-link to="/news" aria-label="See all news">See all <uro-icon icon="chevron-right" /></nuxt-link>
        </template>

        <card-collection :cards="cards" />
    </uro-section>
</template>

<script>
import { kebabCase } from 'lodash';

import CardCollection from '~/patterns/molecules/card-collection/card-collection.vue';
import UroSection from '~/patterns/molecules/section/section.vue';
import pillsCards from '~/mixins/pillsCards.vue';

import relatedNewsQuery from '~/graphql/queries/news/related.graphql';
import { formats, formatSingleDate } from '~/helpers/date';

export default {
    components: {
        UroSection,
        CardCollection
    },

    mixins: [
        pillsCards
    ],

    props: {
        title: {
            type: String,
            default: null,
            required: true
        },
        subtitle: {
            type: String,
            default: null,
            required: false
        },
        tags: {
            type: Array,
            default: () => [],
            required: true
        },
        news: {
            type: Array,
            default: () => [],
            required: true
        }
    },

    data() {
        return {
            filledNews: []
        };
    },

    async fetch() {
        // Exclude news items already added manually
        const excludeIds = this.news ? this.news.map(({ id }) => id) : [],
            // Check if the new news articles need to be related by tags
            tagIds = this.tagIds();
        this.filledNews = [...this.news];

        // If less than 3 or 4 news articles were passed, collect the rest
        if (this.$route.name === 'topics-slug' && (!this.news || this.news.length < this.cardLimit)) {
            // Check how many more news articles need to be queried
            const limit = this.news.length ? this.cardLimit - this.news.length : this.cardLimit;
            const queryVariables = {
                limit,
                exclude: ['not', ...excludeIds],
                relatedToEntries: [{ slug: this.$route.params.slug, type: 'topic' }]
            };

            const news = await this.$gql.executeQuery(relatedNewsQuery, queryVariables);

            if (news.data && news.data.news) {
                this.filledNews.push.apply(this.filledNews, news.data.news);
            }
        }

        // If less than 3 or 4 news articles found, collect the rest
        if (!this.filledNews || this.filledNews.length < this.cardLimit) {
            // Check how many more news articles need to be queried
            const limit = this.filledNews.length ? this.cardLimit - this.filledNews.length : this.cardLimit;
            const queryVariables = {
                limit,
                exclude: ['not', ...excludeIds],
                tagIds
            };

            const news = await this.$gql.executeQuery(relatedNewsQuery, queryVariables);

            if (news.data && news.data.news) {
                this.filledNews.push.apply(this.filledNews, news.data.news);
            }
        }
    },

    computed: {
        cardLimit() {
            if (this.$route.name === 'related-content') {
                return 4;
            } else {
                return 3;
            }
        },

        cards() {
            if (this.filledNews) {
                return this.filledNews.map((news) => {
                    let image = null;
                    if (news.image.length) {
                        image = {
                            src: news.image?.[0]?.large,
                            srcset: `${news.image?.[0]?.large} 600w, ${news.image?.[0]?.medium} 407w, ${news.image?.[0]?.small} 327w`
                        };
                    }
                    return {
                        image,
                        title: news.title,
                        meta: formatSingleDate(news.postDate, formats.dayMonthYear),
                        pills: this.getCardPills(news),
                        uri: news.uri
                    };
                });
            }
            return [];
        }
    },

    methods: {
        kebabCase,
        tagIds() {
            if (this.tags) {
                return this.tags.map(({ id }) => id);
            }
            return null;
        }
    }
};
</script>
