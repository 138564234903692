<template>
    <div class="uro-endorsements">
        <uro-endorsement
            v-for="item in endorsementItems"
            :key="item.id"
            :title="item.title"
            :image="item.image"
            :url="item.url"
            class="uro-endorsements__item"
        />
    </div>
</template>

<script>
import UroEndorsement from '~/patterns/molecules/endorsement/endorsement.vue';

export default {
    components: {
        UroEndorsement
    },

    props: {
        items: {
            type: Array,
            required: false,
            default: null
        }
    },

    computed: {
        endorsementItems() {
            return this.items.map((item) => {
                return {
                    title: item.blockTitle,
                    image: item.image,
                    url: item.externalLink
                };
            });
        }
    }
};
</script>

<style lang="less" src="./endorsements.less" />
