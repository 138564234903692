<template>
    <uro-section>
        <uro-quote>
            <template #default>{{ blockQuoteText }}</template>
            <template #author>{{ blockQuoteAuthor }}</template>
        </uro-quote>
    </uro-section>
</template>

<script>
import UroQuote from '~/patterns/atoms/quote/quote.vue';
import UroSection from '~/patterns/molecules/section/section.vue';

export default {
    components: {
        UroQuote,
        UroSection
    },

    props: {
        blockQuoteText: {
            type: String,
            default: null,
            required: true
        },
        blockQuoteAuthor: {
            type: String,
            default: null,
            required: false
        },
    }
};
</script>
